<template>
  <base-modal
    title="정산 실패 안내"
    sub-title="하단의 내용을 확인해서 정상적으로 정산을 받아보세요. 해결이 어려우시다면 고객센터로 문의 부탁드려요."
    position="center"
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <div class="reason-section">
        <p class="b-text-1">{{ failReason }}</p>
      </div>

      <button class="confirm-btn sub-title-s1" @click="actions.closeModal()">
        확인
      </button>
    </template>
  </base-modal>
</template>

<script>
import { reactive } from "vue";
import BaseModal from "../../../../components/common/BaseModal";

export default {
  name: "CalculateFailModal",
  components: { BaseModal },
  props: {
    failReason: {
      type: String,
      default: "",
    },
  },
  emits: ["confirm", "hideModal"],
  setup(props, { emit }) {
    const state = reactive({});

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      confirm: () => {
        emit("confirm");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
