<template>
  <div
    :class="{
      'status-success': state.calculateStatus === 'success',
      'status-fail': state.calculateStatus === 'fail',
    }"
    @click="actions.goToCalculateDetail()"
  >
    <div class="calculate-detail-item-mo">
      <card-basic>
        <p class="calc-id sub-text-s2 text-gray-second">
          {{ state.merchantUid }}
        </p>

        <h4>{{ moneyInOutItem.title }}</h4>

        <ul class="info-list">
          <li>
            <span class="b-text-3 text-gray-second">판매 일시</span>
            <span class="b-text-1">{{ state.paidAt }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">구매자</span>
            <span class="b-text-1"> {{ moneyInOutItem.user }} </span>
          </li>
          <!-- note 일단 보류-->
          <li>
            <span class="b-text-3 text-gray-second">구매 상태</span>
            <span class="b-text-1 status">{data}</span>
          </li>
        </ul>

        <hr class="hr-third" />

        <ul class="info-list">
          <li>
            <span class="b-text-3 text-gray-second">판매 금액</span>
            <span class="b-text-1">{{ state.amount }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">부가세</span>
            <span class="complete b-text-1">{{ state.valueAddedTax }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">PG 수수료</span>
            <span class="b-text-1">{{ state.pgFees }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">서비스 수수료</span>
            <span class="b-text-1">{{ state.fees }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">
              정산 예정 금액
              <help-tooltip
                :tooltip-text="` 정산 예정 금액은 소득세와 포인트가 합산되지 않은<br />
                  금액으로 실수령 금액과 차이가 있을 수 있어요`"
              ></help-tooltip>
            </span>
            <span class="b-text-1 result-price">{{ state.payout }}</span>
          </li>
        </ul>
      </card-basic>
    </div>

    <div class="calculate-detail-item-pc">
      <div class="calculate-detail-item">
        <span class="col1 b-text-2">
          {{ state.paidAt }}<br />
          {{ state.merchantUid }}
        </span>
        <span class="col2 b-text-2">{{ moneyInOutItem.title }}</span>
        <span class="col3 b-text-2">{{ moneyInOutItem.user }}</span>
        <!--	note 구매완료 or 정산 후 환불 => 상태에 대한 값이 불명확하다.  -->
        <span class="col4 b-text-2 status">
          {{ state.statusText }}
          <help-tooltip
            v-if="state.calculateStatus === 'fail'"
            icon-color="#FF4646"
            :tooltip-text="`이전 정산 이후에 환불되어<br/>이번 정산에서 차감된 내역이에요`"
          ></help-tooltip>
        </span>
        <span class="col5 b-text-2">{{ state.amount }}</span>
        <span class="col6 b-text-2">{{ state.valueAddedTax }}</span>
        <span class="col7 b-text-2">{{ state.pgFees }}</span>
        <span class="col8 b-text-2">{{ state.fees }}</span>
        <span class="col9 b-text-2 result-price">{{ state.payout }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CardBasic from "../../../../components/console/cards/CardBasic";
import { computed, reactive } from "vue";
import router from "../../../../router";
import { settlementPriceFormat } from "@/helper/settlement";
import { dateFormat } from "@/helper/date";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";

export default {
  name: "MoneyInOutItem",
  components: { HelpTooltip, CardBasic },
  props: {
    moneyInOutItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      paymentOrder: computed(() => {
        return props.moneyInOutItem.paymentOrder;
      }),
      merchantUid: computed(() => {
        return state.paymentOrder.merchantUid;
      }),
      paidAt: computed(() => {
        return dateFormat(state.paymentOrder.paidAt, "YYYY.MM.DD");
      }),
      calculateStatus: computed(() => {
        return "success"; // success, fail
      }),
      statusText: computed(() => {
        // if (state.calculateStatus === "fail") {
        //   return "정산 후 환불";
        // }
        // return "구매 완료";
        return "{data}";
      }),
      amount: computed(() => {
        return settlementPriceFormat(
          props.moneyInOutItem.currency,
          props.moneyInOutItem.amount
        );
      }),
      valueAddedTax: computed(() => {
        return settlementPriceFormat(
          props.moneyInOutItem.currency,
          props.moneyInOutItem.valueAddedTax
        );
      }),
      fees: computed(() => {
        return settlementPriceFormat(
          props.moneyInOutItem.currency,
          props.moneyInOutItem.fees
        );
      }),
      pgFees: computed(() => {
        return settlementPriceFormat(
          props.moneyInOutItem.currency,
          props.moneyInOutItem.pgFees
        );
      }),
      payout: computed(() => {
        return settlementPriceFormat(
          props.moneyInOutItem.currency,
          props.moneyInOutItem.payout
        );
      }),
    });

    const actions = {
      goToCalculateDetail: () => {
        router.push({ name: "console.settlement.calculate.show" });
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
