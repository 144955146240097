<template>
  <div class="page-container-new">
    <template v-if="!state.pageLoading">
      <page-header title="상세정보" :back-button="true"></page-header>

      <div class="container-body">
        <div class="summary">
          <card-basic class="actual-amount">
            <div class="total">
              <span class="sub-text-s2 text-gray-reverse">실수령 금액</span>
              <h3 class="text-gray-reverse">{{ state.settlementAmount }}</h3>
            </div>

            <ul class="detail-list">
              <li>
                <span class="sub-text-s2 text-gray-second">판매 금액</span>
                <span class="sales-price sub-title-s2">
                  {{ state.settlementPayout }}
                </span>
              </li>
              <li>
                <span class="sub-text-s2 text-gray-second">부가세</span>
                <span class="sub-title-s2">{{ state.settlementVAT }}</span>
              </li>
              <li>
                <span class="sub-text-s2 text-gray-second"
                  >빅크 포인트 사용</span
                >
                <span class="point sub-title-s2">
                  {{ state.settlementPoint }}
                </span>
              </li>
              <li>
                <span class="sub-text-s2 text-gray-second">PG 수수료</span>
                <span class="sub-title-s2">{{ state.settlementPGFee }}</span>
              </li>
              <li>
                <span class="sub-text-s2 text-gray-second"
                  >서비스 이용 수수료</span
                >
                <span class="sub-title-s2">{{
                  state.settlementServiceFee
                }}</span>
              </li>
              <li>
                <span class="sub-text-s2 text-gray-second">소득세 신고</span>
                <span class="sub-title-s2">{{ state.settlementTax }}</span>
              </li>
            </ul>
          </card-basic>

          <div class="info-wrapper">
            <card-basic
              class="calculate-info"
              :class="
                state.settlementStatus ===
                settlementConst.settlementStatus.fail.value
                  ? 'status-fail'
                  : 'status-success'
              "
            >
              <h4>정산 정보</h4>
              <ul class="info-list">
                <li>
                  <p class="key sub-text-s3 text-gray-second">정산 ID</p>
                  <p class="value sub-text-s2">{{ settlementResourceId }}</p>
                </li>
                <li>
                  <p class="key sub-text-s3 text-gray-second">정산 등록일</p>
                  <p class="value sub-text-s2">{{ state.settlementedAt }}</p>
                </li>
                <li>
                  <p class="key sub-text-s3 text-gray-second">정산일</p>
                  <p class="value sub-text-s2">{{ state.paidAt }}</p>
                </li>
                <li>
                  <p class="key sub-text-s3 text-gray-second">상태</p>
                  <p class="value status sub-text-s2">
                    {{ state.statusText }}
                    <span
                      v-if="
                        state.settlementStatus ===
                        settlementConst.settlementStatus.fail.value
                      "
                      class="detail e-text-1"
                      @click="toggleFailReasonModal()"
                    >
                      자세히
                    </span>
                  </p>
                </li>
                <li>
                  <p class="key sub-text-s3 text-gray-second">
                    판매 금액(VAT 포함)
                  </p>
                  <p class="value sub-text-s2">{{ state.payout }}</p>
                </li>
                <li>
                  <p class="key sub-text-s3 text-gray-second">실수령 금액</p>
                  <p class="value sub-text-s2">{{ state.settlementAmount }}</p>
                </li>
              </ul>
            </card-basic>

            <card-basic class="account-info">
              <h4>정산 계좌 정보</h4>
              <ul class="info-list">
                <li>
                  <p class="key sub-text-s3 text-gray-second">정산 은행</p>
                  <p class="value sub-text-s2">{{ state.bankInfo.bank }}</p>
                </li>
                <li>
                  <p class="key sub-text-s3 text-gray-second">예금주</p>
                  <p class="value sub-text-s2">
                    {{ state.bankInfo.bankAccountHolder }}
                  </p>
                </li>
                <li>
                  <p class="key sub-text-s3 text-gray-second">정산 계좌번호</p>
                  <p class="value sub-text-s2">
                    {{ state.bankInfo.bankAccount }}
                  </p>
                </li>
              </ul>
            </card-basic>
          </div>
        </div>

        <h4 class="list-title">포함된 정산 내역</h4>

        <div class="table-header">
          <span class="col1 sub-text-s3 text-gray-second">
            판매 일시/주문 번호
          </span>
          <span class="col2 sub-text-s3 text-gray-second">상품명</span>
          <span class="col3 sub-text-s3 text-gray-second">구매자</span>
          <span class="col4 sub-text-s3 text-gray-second">구매 상태</span>
          <span class="col5 sub-text-s3 text-gray-second">판매 금액</span>
          <span class="col6 sub-text-s3 text-gray-second">부가세</span>
          <span class="col7 sub-text-s3 text-gray-second">PG 수수료</span>
          <span class="col8 sub-text-s3 text-gray-second">서비스 수수료</span>
          <span class="col9 sub-text-s3 text-gray-second">
            정산 예정 금액
            <help-tooltip
              :tooltip-text="` 정산 예정 금액은 소득세와 포인트가 합산되지 않은<br />
                  금액으로 실수령 금액과 차이가 있을 수 있어요`"
            ></help-tooltip>
          </span>
        </div>

        <ul class="money-inout__list">
          <li
            v-for="moneyInOutItem in state.moneyInOutList"
            :key="`money-inout-${moneyInOutItem.resourceId}`"
          >
            <money-in-out-item
              :money-in-out-item="moneyInOutItem"
            ></money-in-out-item>
          </li>
        </ul>

        <div
          v-if="state.settlementDetailMeta.pagination.lastPage > 1"
          class="pagination-wrapper"
        >
          <pagination
            :pagination="state.settlementDetailMeta.pagination"
            @getPageData="(page) => getSettlementDetail({ page: page })"
          ></pagination>
        </div>
      </div>
    </template>
    <teleport :disabled="true">
      <calculate-fail-modal
        v-if="state.showFailReasonModal"
        :fail-reason="state.calculateSummary.information.failReason"
        @hideModal="toggleFailReasonModal()"
      ></calculate-fail-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import CardBasic from "@/components/console/cards/CardBasic";
import PageHeader from "@/components/console/headers/PageHeader";
import CalculateFailModal from "../CalculateFailModal/CalculateFailModal";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import MoneyInOutItem from "../MoneyInOutItem/MoneyInOutItem";
import Pagination from "@/components/console/pagination/Pagination";
import { pointFormat, settlementPriceFormat } from "@/helper/settlement";
import { dateFormat } from "@/helper/date";
import settlementConst from "@/consts/settlement";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";

export default {
  name: "CalculateDetail",
  components: {
    HelpTooltip,
    Pagination,
    MoneyInOutItem,
    CalculateFailModal,
    PageHeader,
    CardBasic,
  },
  props: {},
  setup() {
    const store = useStore();
    const route = useRoute();

    const settlementResourceId = route.params.settlementResourceId;

    const state = reactive({
      pageLoading: true,
      showFailReasonModal: false,
      moneyInOutList: computed(() => {
        return store.getters["settlements/settlementDetail"];
      }),
      settlementDetailMeta: computed(() => {
        return store.getters["settlements/settlementDetailMeta"];
      }),
      calculateSummary: computed(() => {
        return state.settlementDetailMeta.settlement;
      }),
      currency: computed(() => {
        return state.settlementDetailMeta.settlement.currency;
      }),
      settlementAmount: computed(() => {
        return settlementPriceFormat(
          state.currency,
          state.calculateSummary.detail.amount
        );
      }),
      settlementPayout: computed(() => {
        return settlementPriceFormat(
          state.currency,
          state.calculateSummary.detail.payout
        );
      }),
      settlementVAT: computed(() => {
        return `-${settlementPriceFormat(
          state.currency,
          state.calculateSummary.detail.VAT
        )}`;
      }),
      settlementPoint: computed(() => {
        return pointFormat(state.calculateSummary.detail.point);
      }),
      settlementPGFee: computed(() => {
        return `-${settlementPriceFormat(
          state.currency,
          state.calculateSummary.detail.pgFee
        )}`;
      }),
      settlementServiceFee: computed(() => {
        return `-${settlementPriceFormat(
          state.currency,
          state.calculateSummary.detail.serviceFee
        )}`;
      }),
      settlementTax: computed(() => {
        return `-${settlementPriceFormat(
          state.currency,
          state.calculateSummary.detail.tax
        )}`;
      }),
      bankInfo: computed(() => {
        return state.calculateSummary.bankInformation;
      }),
      settlementedAt: computed(() => {
        return dateFormat(
          state.calculateSummary.information.settlementedAt,
          "YYYY.MM.DD"
        );
      }),
      paidAt: computed(() => {
        return dateFormat(
          state.calculateSummary.information.paidAt,
          "YYYY.MM.DD"
        );
      }),
      settlementStatus: computed(() => {
        return state.calculateSummary.information.status;
      }),
      statusText: computed(() => {
        if (
          state.settlementStatus === settlementConst.settlementStatus.fail.value
        ) {
          return settlementConst.settlementStatus.fail.text;
        }
        return settlementConst.settlementStatus.confirm.text;
      }),
      payout: computed(() => {
        return settlementPriceFormat(
          state.currency,
          state.calculateSummary.information.payout
        );
      }),
    });

    const getSettlementDetail = (params) => {
      store
        .dispatch("settlements/getSettlement", {
          settlementResourceId: settlementResourceId,
          data: params,
        })
        .then(() => {
          state.pageLoading = false;
        });
    };

    onBeforeMount(async () => {
      getSettlementDetail();
    });

    const toggleFailReasonModal = () => {
      state.showFailReasonModal = !state.showFailReasonModal;
    };

    const actions = {};

    return {
      state,
      actions,
      getSettlementDetail,
      settlementResourceId,
      settlementConst,
      toggleFailReasonModal,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
